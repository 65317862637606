<template>
  <div class="shop">
    <Loading
      :loading="loading"
      message="You are being redirected to the store."
    />
    <div v-if="url">
      <p><a href="https://tripleaughtdesign.com">Continue to the store</a>.</p>
    </div>
    <div v-else-if="loadingError">
      <p>Something went wrong while redirecting you to the store.</p>
    </div>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import Loading from '@/components/Loading.vue'

export default {
  components: {
    Loading,
  },
  data: function() {
    return {
      loading: false,
      loadingError: false,
      url: null,
    }
  },
  created: function () {
    this.getURL()
  },
  methods: {
    getURL() {
      this.url = null
      this.loading = true
      this.loadingError = false
      return allianceAPI.get(`/alliance/multipass/`)
        .then((response) => {
          if (response.data.url) {
            this.url = response.data.url
            window.location.href = this.url
          } else {
            this.loadingError = true
          }
         })
        .catch(() => this.loadingError = true)
        .finally(() => this.loading = false)
    },
  },
}
</script>
